// src/ProductItem.tsx
import React, { useState, useEffect, useRef } from "react";
import { CustomerTier, Product } from "./MyTypes";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useCart } from "./CartContext";
import { useSnackbar } from "notistack";

import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

import { useAuthenticator } from "@aws-amplify/ui-react";

import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import CircularProgress from "@mui/material/CircularProgress";

import { readAndCompressImage } from "browser-image-resizer";
import { Box, TextField } from "@mui/material";

import ZoomInIcon from "@mui/icons-material/ZoomIn";

interface ProductItemProps {
  product: Product;
  tier: CustomerTier;
  onStartEditingProduct: (product: Product, action: string) => void;
}

async function isAdmin() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    return groups && groups.includes("admin");
  } catch (error) {
    console.error("Error checking user group:", error);
    return false;
  }
}

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  tier,
  onStartEditingProduct,
}) => {
  const { addItem, items, selectedCustomer } = useCart();
  const [quantity, setQuantity] = useState<number>(1);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const { user } = useAuthenticator();

  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const adminStatus = await isAdmin();
      setIsAdminUser(adminStatus);
    };

    checkAdminStatus();
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const cartItem = items.find((item) => item.id === product.id);
  const quantityInCart = cartItem ? cartItem.quantity : 0;

  const handleImageUploadIconClick = () => {
    // programmatically click the file input
    fileInputRef.current?.click();
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      setQuantity(newQuantity);
    }
  };

  const fetchSignedURL = async (productId?: string) => {
    try {
      const response = await API.post(
        "medineeds",
        "/adminUploadProductImage?productId=" +
          productId?.replace(/'/g, "").replace(/"/g, ""),
        {
          headers: {
            Authorization: `Bearer ${user
              .getSignInUserSession()
              ?.getIdToken()
              .getJwtToken()}`,
          },
        }
      );
      const uploadUrl = response.uploadUrl;
      console.log("uploadUrl:", uploadUrl);
      return uploadUrl;
    } catch (error) {
      console.error("Error fetching uploadUrl:", error);
      return [];
    } finally {
      // setIsImageLoading(false);
    }
  };

  // TODO move this function to the parant. There is already a duplicate. Use the OnStartEditingProduct to update the product directly
  // isntead of launching the model in the parent
  const updateProduct = async (product: Product) => {
    setIsLoading(true);
    try {
      await API.put("medineeds", "/products", {
        headers: {
          Authorization: `Bearer ${user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
        },
        body: [product],
      });

      await onStartEditingProduct(product, "UpdateImage");
      return true;
    } catch (error) {
      console.error("Error updating  product:", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const addToCart = () => {
    setIsLoading(true);
    try {
      const updatedQuantity = addItem(product, quantity);
      enqueueSnackbar(
        `Added ${quantity} ${product.name}(s) to cart. Total: ${updatedQuantity}`,
        { variant: "success" }
      );
      setQuantity(1);
    } catch (error) {
      console.error("Error adding to cart", error);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async (file: File, signedUrl: string) => {
    const options = {
      method: "PUT",
      body: file,
    };

    try {
      const response = await fetch(signedUrl, options);

      if (response.status !== 200) {
        throw new Error("Failed to upload file");
      }

      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    product: Product
  ) => {
    setIsLoading(true);
    setIsImageLoading(true);

    console.log("id->", product.id);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const config = {
        quality: 0.7, // The quality of the resized image, ranging from 0 to 1 (1 being the highest quality).
        maxWidth: 800, // The max width of the resized image.
        maxHeight: 800, // The max height of the resized image.
      };

      readAndCompressImage(file, config)
        .then((resizedImage) => {
          const reader = new FileReader();
          let newImgURL = "";

          reader.onload = async (event) => {
            if (event.target && event.target.result) {
              fetchSignedURL(product.id?.toString())
                .then(async (uploadURL) => {
                  await uploadFile(
                    new File([resizedImage], file.name, { type: file.type }),
                    uploadURL
                  ).then((e) => {
                    newImgURL = uploadURL.split("?")[0];
                    console.log("compressed upload complete", newImgURL);
                  });
                })
                .then(() => {
                  product.imageUrl = newImgURL;
                  updateProduct(product).then(() => {
                    setIsImageLoading(false);
                  });
                })
                .catch(() => {
                  setIsImageLoading(false);
                });
            }
          };

          reader.readAsDataURL(resizedImage);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <Card
        sx={{ maxWidth: 345, position: "relative" }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Box
          position="relative"
          // onMouseEnter={() => setIsHovering(true)}
          // onMouseLeave={() => setIsHovering(false)}
        >
          {isImageLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            product?.imageUrl && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "157px",
                    right: "5px",
                  }}
                >
                  <IconButton
                    onClick={() => onStartEditingProduct(product, "Zoom")}
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.7)", // semi-transparent white background
                    }}
                  >
                    <ZoomInIcon id="ZoomInIcon" color="primary" />
                  </IconButton>
                </div>
                {/* </div> */}
                <CardMedia
                  component="img"
                  height="200"
                  image={product.imageUrl}
                  alt={product.name}
                />
              </Box>
            )
          )}
          {isAdminUser && (
            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                display: !isHovering ? "none" : "",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "120px",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
              >
                <label
                  htmlFor={"file_" + product.id?.toString()}
                  style={{ display: !isHovering ? "none" : "" }}
                >
                  <input
                    key={product.id}
                    type="file"
                    id={"file_" + product.id?.toString()}
                    onChange={(e) => handleImageUpload(e, product)}
                    style={{ display: "none" }}
                    capture="environment"
                    ref={fileInputRef}
                  />
                  <Tooltip title="Click here to upload a new photo for this product">
                    <IconButton onClick={handleImageUploadIconClick}>
                      <CloudUploadIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </label>
                <Tooltip title="Click here to change the name and price of this product">
                  <IconButton
                    onClick={() => onStartEditingProduct(product, "Update")}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete product">
                  <IconButton
                    disabled={isLoading}
                    onClick={() => {
                      onStartEditingProduct(product, "Delete");
                    }}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            // </Box>
          )}
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {product.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {product.description}
          </Typography>
          {tier === CustomerTier.NoPrice ? (
            "Regular price"
          ) : (
            <Typography variant="h6" sx={{ mt: 1 }}>
              {product.price === -1 ? "Price changed - please refresh" : ""}
              {selectedCustomer?.tier !== CustomerTier.NoPrice
                ? "₹" + Number.parseFloat(product.price.toString()).toFixed(2)
                : "Regular Price"}
            </Typography>
          )}
          <div>
            <Tooltip title="Decrease Quantity">
              <span>
                <IconButton
                  onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                  size="small"
                  disabled={quantity === 1}
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
            <TextField
              type="number"
              size="small"
              inputProps={{
                min: 1,
                style: { textAlign: "center", width: "50px" }, // adjust width as needed
              }}
              value={quantity}
              onChange={handleQuantityChange}
            />
            <Tooltip title="Increase Quantity">
              <IconButton
                onClick={() => setQuantity(quantity + 1)}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Button
            disabled={isLoading}
            onClick={addToCart}
            variant="contained"
            color="info"
            sx={{ mt: 2 }}
          >
            {isLoading ? "Loading..." : "Add to Cart"}
          </Button>
          <Typography
            style={{
              position: "absolute",
              width: "100%",
              textAlign: "center",
              paddingRight: "20px",
            }}
            variant="body1"
            color="text.secondary"
          >
            {quantityInCart === 0 ? "" : "In cart : " + quantityInCart}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default ProductItem;
