// OrderRow.tsx
import React from 'react';
import {  useNavigate } from "react-router-dom";
import { SalesOrder } from '../MyTypes';

interface OrderRowProps {
  order: SalesOrder;
  rowIndex: number;
}

const OrderRow: React.FC<OrderRowProps> = ({ order, rowIndex }) => {
  const navigate = useNavigate();

  const goToOrderDetails = () => {
    navigate(`/orders/${order.id}`);
  };

  return (
<>
    <tr style={{
        background: rowIndex % 2 === 0 ? 'white' : 'aliceblue'
      }}  onClick={goToOrderDetails}>
      <td  style={{
                  padding: '5px',
                  borderTop: 'solid 1px gray',
                }}>{order.id}</td>
      <td style={{
                  padding: '5px',
                  borderTop: 'solid 1px gray',
                }}>{new Date(order.date).toLocaleDateString()}</td>
      <td style={{
                  padding: '5px',
                  borderTop: 'solid 1px gray',
                }}>{order.customerBusinessName}</td>
      <td style={{
                  padding: '5px',
                  borderTop: 'solid 1px gray',
                }}> {order.totalPrice}</td>
      <td style={{
                  padding: '5px',
                  borderTop: 'solid 1px gray',
                }}>{order.salesRepBusinessName}</td>
      <td style={{
                  padding: '5px',
                  borderTop: 'solid 1px gray',
                }}>{order.totalCommission}</td>
      
    </tr></>
  );
};

export default OrderRow;
