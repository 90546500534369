/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VxJdhGdlS",
    "aws_user_pools_web_client_id": "79tspkbo4b7b213s5aava04l1b",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    aws_cloud_logic_custom: [
        {
          name: "medineeds",
          endpoint: "https://w25cs5v2ti.execute-api.us-east-1.amazonaws.com/dev",
            // endpoint: "http://127.0.0.1:3000",
          region: "us-east-1",
        },
      ],
};


export default awsmobile;
