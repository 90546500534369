// src/ProductList.tsx
import React, { useCallback, useRef } from "react";
import { Customer, CustomerTier, Product } from "./MyTypes";
import ProductItem from "./ProductItem";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useEffect } from "react";

import { API, Auth } from "aws-amplify";

import { useAuthenticator } from "@aws-amplify/ui-react";

import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";

import ClearIcon from "@mui/icons-material/Clear";

import { useCart } from "./CartContext";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Switch,
  TextField,
  Zoom,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useSnackbar } from "notistack";

interface ProductListProps {
  products_to_remove: Product[];
}

const ITEMS_PER_PAGE = 50;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

const ProductList: React.FC<ProductListProps> = ({ products_to_remove }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [renderedProducts, setrenderedProducts] = useState<Product[]>([]);
  const { user } = useAuthenticator();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [customerTier, setCustomerTier] = React.useState<CustomerTier>(
    CustomerTier.Tier1
  );
  const [isZoomOpen, setIsZoomOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  // const [productToDelete, setProductToDelete] = useState<Product | null>(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedAdjustedPrice, setEditedAdjustedPrice] = useState<number | null>(
    0
  );
  const [editedIsPriceControlled, setEditedIsPriceControlled] =
    useState<number>(0);

  const { selectedCustomer } = useCart();
  const searchInputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();

  // const debouncedSetDebouncedSearchTerm = useRef(
  //   debounce((value) => setDebouncedSearchTerm(value), 300)
  // );
  // const debouncedHandleInputChange = useRef(
  //   debounce((value) => {
  //     // Your state updates or any other logic you want to delay here
  //     setDebouncedSearchTerm(value);
  //   }, 300)
  // ).current;
  // const debouncedSetDebouncedSearchTerm = useRef(
  //   debounce((value) => setDebouncedSearchTerm(value), 200)
  // ).current;

  // const debouncedSetDebouncedSearchTerm = useCallback(
  //   debounce((value) => setDebouncedSearchTerm(value), 200),
  //   []
  // );
  const debouncedHandleInputChange = debounce(
    (value) => setDebouncedSearchTerm(value),
    100
  );
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.idToken.payload["cognito:groups"];
      const isWorkingOnBehalfOfBuyer =
        groups && (groups.includes("admin") || groups.includes("sales_rep"));

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await API.get("medineeds", "/getProducts", {
          headers: {
            Authorization: `Bearer ${user
              .getSignInUserSession()
              ?.getIdToken()
              .getJwtToken()}`,
            Selectedcustomeremail: isWorkingOnBehalfOfBuyer
              ? selectedCustomer?.email
              : undefined,
          },
        });

        return response;
      } catch (error) {
        console.error("Error fetching products:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    };
    fetchProducts().then((fetchedProducts) => {
      setProducts(fetchedProducts);
      setHasMoreItems(fetchedProducts.length >= ITEMS_PER_PAGE);
      console.log("Products:->", fetchedProducts.length);
      setrenderedProducts(fetchedProducts.slice(0, ITEMS_PER_PAGE));
    });
    const fetchCustomerDetails = async () => {
      console.log("Customer details call coming in from pricelist:->");

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await API.get("medineeds", "/customers/details", {
          headers: {
            Authorization: `Bearer ${user
              .getSignInUserSession()
              ?.getIdToken()
              .getJwtToken()}`,
          },
        });

        return response;
      } catch (error) {
        console.error("Error fetching Customer details:");
        return [];
      }
    };
    fetchCustomerDetails().then((fetchedCustomer: Customer[]) => {
      setCustomerTier(
        fetchedCustomer && (fetchedCustomer[0]?.tier as CustomerTier)
      );
    });
  }, []);

  // useEffect(() => {
  //   console.log("handle serach useeffect");
  //   const handleSearch = debounce((searchTerm) => {
  //     console.log("handle serach calling ", searchTerm, debouncedSearchTerm);
  //     setDebouncedSearchTerm(searchTerm);
  //   }, 200);

  //   handleSearch(searchTerm);

  //   // Cleanup
  //   return () => {
  //     handleSearch.cancel();
  //   };
  // }, [searchTerm]);

  useEffect(() => {
    console.log(
      "handle serach debouncedSearchTerm use effect ",
      searchTerm,
      debouncedSearchTerm
    );
    setIsLoading(true);
    // setTimeout(() => {
    console.log(
      "handle serach debouncedSearchTerm in settimeout doing filtering ",
      searchTerm,
      debouncedSearchTerm
    );
    setFilteredProducts(
      products.filter((product) =>
        product.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
    );
    console.log(
      "handle serach debouncedSearchTerm filtering done ",
      searchTerm,
      debouncedSearchTerm
    );
    setIsLoading(false);
    // }, 10);
  }, [debouncedSearchTerm]);

  // Trigger focus when search term is updated
  useEffect(() => {
    if (!isLoading) {
      (searchInputRef.current as any).focus();
    }
  }, [isLoading]);

  const handleClear = () => {
    setDebouncedSearchTerm("");
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const fetchProductsToRender = () => {
    const newRenderedProducts = [
      ...renderedProducts,
      ...products.slice(
        renderedProducts.length,
        renderedProducts.length + ITEMS_PER_PAGE
      ),
    ];
    const newHasMoreItems = products.length > newRenderedProducts.length;

    console.log(
      "more Products:->",
      [
        ...renderedProducts,
        ...products.slice(
          renderedProducts.length,
          renderedProducts.length + ITEMS_PER_PAGE
        ),
      ],
      products.length >= renderedProducts.length
    );
    setrenderedProducts(newRenderedProducts);
    setHasMoreItems(newHasMoreItems);
  };

  const handleProductDelete = (productId: number) => {
    setProducts(products.filter((product) => product.id !== productId));
    setFilteredProducts(
      filteredProducts.filter((product) => product.id !== productId)
    );
    setrenderedProducts(
      renderedProducts.filter((product) => product.id !== productId)
    );
  };

  const handleProductUpdate = (updatedProduct: Product) => {
    setProducts(
      products.map((product) => {
        if (updatedProduct.id === product.id) {
          let returnVal = {
            ...updatedProduct,
            imageUrl: updatedProduct.imageUrl + "?t=" + new Date().getTime(),
          };
          console.log("returnVal updated->", returnVal);
          return returnVal;
        } else return product;
      })
    );
    setrenderedProducts(
      renderedProducts.map((product) => {
        if (updatedProduct.id === product.id) {
          let returnVal = {
            ...updatedProduct,
            imageUrl: updatedProduct.imageUrl + "?t=" + new Date().getTime(),
          };
          console.log("returnVal updated->", returnVal);
          return returnVal;
        } else return product;
      })
    );
    setFilteredProducts(
      filteredProducts.map((product) => {
        if (updatedProduct.id === product.id) {
          let returnVal = {
            ...updatedProduct,
            imageUrl: updatedProduct.imageUrl + "?t=" + new Date().getTime(),
          };
          console.log("returnVal updated->", returnVal);
          return returnVal;
        } else return product;
      })
    );
  };

  const toggleZoom = () => {
    setIsZoomOpen(!isZoomOpen);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await API.del("medineeds", `/products`, {
        headers: {
          Authorization: `Bearer ${user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
        },

        body: [editingProduct],
      });

      enqueueSnackbar(`Deleted product: ${editingProduct?.name}`, {
        variant: "success",
      });
      handleProductDelete(editingProduct?.id as number);
      setEditingProduct(null);
    } catch (error) {
      console.error("Error deleting product:", error);
      enqueueSnackbar(
        `Failed to delete product with id ${editingProduct?.name}`,
        {
          variant: "error",
        }
      );
    } finally {
      setIsLoading(false);
    }
  };
  const updateProduct = async (product: Product) => {
    setIsLoading(true);
    try {
      await API.put("medineeds", "/products", {
        headers: {
          Authorization: `Bearer ${user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
        },
        body: [product],
      });
      // TODO : make sure this is working before final commit. Commenting for now.
      // setImageUrl(product.imageUrl + "?t=" + new Date().getTime());

      return true;
    } catch (error) {
      console.error("Error updating  product:", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateProduct = async () => {
    setIsLoading(true);
    if (editingProduct) {
      const updatedProduct = {
        ...editingProduct,
        name: editedName,
        adjustedPrice: editedAdjustedPrice,
        isPriceControlled: editedIsPriceControlled,
        price:
          editedIsPriceControlled === editingProduct.isPriceControlled
            ? editingProduct.price
            : -1, //-1 to show message on UI to refresh
      };
      try {
        const success = await updateProduct(updatedProduct);
        if (success) {
          // Update successful, close the dialog
          setIsDialogOpen(false);
          setEditingProduct(null);
          handleProductUpdate(updatedProduct);
          enqueueSnackbar(`Updated product: ${updatedProduct.id}`, {
            variant: "success",
          });
        }
      } catch (error) {
        console.error("Error updating product:", error);
        enqueueSnackbar(
          `Failed to update product with id ${updatedProduct.id}`,
          {
            variant: "error",
          }
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const startEditingProduct = (product: Product, action: string) => {
    console.log("startEditingProduct", product);
    setEditingProduct(product);
    if (action === "Zoom") {
      toggleZoom();
    } else if (action === "Delete") {
      setIsDeleteDialogOpen(true);
    } else if (action === "Update") {
      setEditedName(product.name);
      setEditedAdjustedPrice(product.adjustedPrice);
      setEditedIsPriceControlled(product.isPriceControlled);
      setIsDialogOpen(true);
    } else if (action === "UpdateImage") {
      handleProductUpdate(product);
    }
  };

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="200px"
    >
      <LinearProgress />
    </Box>
  ) : (
    <>
      <Container maxWidth="lg">
        <TextField
          inputRef={searchInputRef}
          id="search"
          label="Search Products"
          variant="outlined"
          // value={debouncedSearchTerm}
          onChange={(e) => {
            const { value } = e.target;
            // setSearchTerm(value);
            if (value.length > 0 && value.length < 3) return;
            debouncedHandleInputChange(value);
          }}
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            width: "80%",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {debouncedSearchTerm && (
                  <IconButton size="small" onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <InfiniteScroll
          dataLength={renderedProducts.length}
          next={fetchProductsToRender}
          hasMore={hasMoreItems}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>End of product search results</b>
            </p>
          }
        >
          <Grid container spacing={4}>
            {(debouncedSearchTerm ? filteredProducts : renderedProducts).map(
              (product) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                  <ProductItem
                    key={product.id}
                    product={product}
                    tier={customerTier}
                    onStartEditingProduct={startEditingProduct}
                  />
                </Grid>
              )
            )}
          </Grid>
        </InfiniteScroll>
      </Container>
      <Dialog
        TransitionComponent={Transition}
        open={isZoomOpen}
        onClose={toggleZoom}
      >
        {editingProduct && (
          <img
            src={editingProduct.imageUrl}
            style={{ width: "100%", height: "auto" }}
            alt={editingProduct?.name}
          />
        )}
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle>Delete Product - {editingProduct?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              if (editingProduct) handleDelete();
              setIsDeleteDialogOpen(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        TransitionComponent={Transition}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle id="edit-product-dialog">
          Product id: {editingProduct?.id}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="price"
            label="Adjusted Price"
            value={editedAdjustedPrice}
            onChange={(e) => setEditedAdjustedPrice(Number(e.target.value))}
            fullWidth
          />
          <FormControlLabel
            control={
              <Switch
                checked={editedIsPriceControlled === 1}
                onChange={(e) =>
                  setEditedIsPriceControlled(e.target.checked ? 1 : 0)
                }
                color="primary"
              />
            }
            label={
              "This Product is " +
              (editedIsPriceControlled === 1 ? "" : "NOT ") +
              "Price Controlled"
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={() => setIsDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleUpdateProduct}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductList;
