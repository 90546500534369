import React from 'react';

export interface DefaultColumnFilterProps {
  column: {
    filterValue: string;
    setFilter: (filterValue: string) => void;
  };
}

export const DefaultColumnFilter: React.FC<DefaultColumnFilterProps> = ({
  column: { filterValue, setFilter },
}) => {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || '');
      }}
      placeholder={`Search...`}
      style={{
        fontSize: '1.1rem',
        border: '0',
      }}
    />
  );
};


export interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: string;
  setGlobalFilter: (filterValue: string) => void;
}

export const GlobalFilter: React.FC<GlobalFilterProps> = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;

  return (
    <span>
      Search:{' '}
      <input
        value={globalFilter || ''}
        onChange={(e) => {
          setGlobalFilter(e.target.value || ''); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  );
};



