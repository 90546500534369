import React, { useState, useEffect } from "react";
import { Customer, CustomerStatus, CustomerTier, SalesRep } from "../MyTypes";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";


import Switch from "@mui/material/Switch";


import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

import { useAuthenticator } from "@aws-amplify/ui-react";

import Tooltip from "@mui/material/Tooltip";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Box, CardHeader, DialogContentText, Divider, FormControl, FormControlLabel, IconButton, InputLabel, ListItemIcon, Menu, MenuItem, Select, TextField } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { relative } from "path";

interface CustomerItemProps {
  customer: Customer;
  onDelete: (customerId: number) => void;
  onUpdate: (customer: Customer) => void;
  salesReps?: SalesRep[],
  onStartEditingCustomer: (customer: Customer ) => void;
}

 async function isAdmin() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    return groups && groups.includes("admin");
  } catch (error) {
    console.error("Error checking user group:", error);
    return false;
  }
}

const CustomerItem: React.FC<CustomerItemProps> = ({
  customer,
  onDelete,
  onUpdate,
  salesReps,
  onStartEditingCustomer
}) => {
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const { user } = useAuthenticator();

  

  const [isLoading, setIsLoading] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>(
    null
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const adminStatus = await isAdmin();
      setIsAdminUser(adminStatus);
    };

    checkAdminStatus();
  }, []);

  

  const { enqueueSnackbar } = useSnackbar();

  

  const handleDelete = async (customer: Customer) => {
    setIsLoading(true);
    try {
      await API.del("medineeds", `/customers`, {
        headers: {
          Authorization: `Bearer ${user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
        },

        body: [customer],
      });

      enqueueSnackbar(`Deleted customer: ${customer.email}`, {
        variant: "success",
      });
      onDelete(customer.id as number);
    } catch (error) {
      console.error("Error deleting customer:", error);
      enqueueSnackbar(
        `Failed to delete customer with email ${customer.email}`,
        {
          variant: "error",
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  
  

  return (!customer.isNewCustomer ?(
    <>
      <Card
    sx={{
      maxWidth: 345,
      position: "relative",
      backgroundColor: "inherit",
    }}
    onMouseEnter={() => setIsHovering(true)}
    onMouseLeave={() => setIsHovering(false)}
>
<Box
    height="10px"
    bgcolor={
      customer.status === CustomerStatus.Active
        ? "green"
        : customer.status === CustomerStatus.Pending
        ? "orange"
        : "red"
    }
  />
    <CardHeader 
        title={customer.businessName}
        subheader={`${customer.firstName} ${customer.lastName}`}
    />
    <Box>
        <Typography variant="body2" color="text.secondary">
            {customer.address}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {customer.email}
        </Typography>
    </Box>
    <Divider variant="middle" />
    <Box>
    <Typography variant="subtitle2" color="text.secondary">Can this customer see prices now?: </Typography>
        <Typography variant="h6" color="text.secondary">
            
            {customer.tier === CustomerTier.NoPrice
              ? "No"
              : "Yes,  at "+ customer.tier}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">Sales Rep: </Typography>
        <Typography variant="h6" color="text.secondary">
            {!customer.salesRepId?"No sales rep assigned to this customer": customer.salesRepFirstName +" "+ customer.salesRepLastName}
        </Typography>
    </Box>
    
    {isAdminUser && (<>

        <IconButton style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            display: !isHovering ? "none" : "",
        }}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        >
        <MenuIcon >
        </MenuIcon>
        </IconButton>
        <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
            <MenuItem onClick={() => {handleClose(); onStartEditingCustomer(customer)}}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit
            </MenuItem>
            <MenuItem
                disabled={isLoading}
                onClick={() => {
                  handleClose();
                  setCustomerToDelete(customer);
                  setIsDeleteDialogOpen(true);
                }}
            >
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                Delete
            </MenuItem></Menu></>
        
    )}
</Card>



      <Dialog
  open={isDeleteDialogOpen}
  onClose={() => setIsDeleteDialogOpen(false)}
>
  <DialogTitle>Delete Customer</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to delete the customer: {customerToDelete?.businessName}?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
    <Button
      onClick={() => {
        if (customerToDelete) handleDelete(customerToDelete);
        setIsDeleteDialogOpen(false);
      }}
    >
      Delete
    </Button>
  </DialogActions>
</Dialog>
    </>
  ):<></>);
};

export default CustomerItem;
