// src/App.tsx
import React, { useState } from "react";
import "./App.css";
import ProductList from "./ProductList";
import ShoppingCart from "./routes/ShoppingCart";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from "./CartContext";
import Header from "./Header"; // Import Header
import Footer from "./Footer"; // Import Footer
import { Outlet } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import PublicHomePage from "./routes/PublicHomePage";
import { SnackbarProvider } from "notistack";
import Orders from "./routes/Orders";
import Customers from "./routes/Customers";
import {Admin} from "./routes/Admin";
import { Product } from "./MyTypes";

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';


import "@aws-amplify/ui-react/styles.css";
import OrderDetails from "./Components/OrderDetails";

const ProtectedRoutes: React.FC = () => {
  const { authStatus } = useAuthenticator();

  if ((authStatus as string) !== "authenticated") {
    return <Navigate to="/public/home" />;
  }

  return <Outlet />;
};

const AppContent: React.FC = () => {
  const { authStatus,user } = useAuthenticator();
  const location = useLocation();
  const [productList, setProductList] = useState<Product[]>([]);
  const shouldShowAuthenticator =
    location.pathname !== "/public/home" && location.pathname !== "/";
  // const [authenticatorState, setAuthenticatorState] = React.useState("signIn");
  const initialAuthState = location.state?.initialAuthState || 'signIn';

  const protectedContent = (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/pricelist" element={<ProductList products_to_remove={productList?.length>0?productList:productList} />} />
        <Route path="/cart" element={<ShoppingCart />} />
        <Route path="/orders" element={<Orders />}/>
        <Route path="/orders/:orderId" element={<OrderDetails />} />
        <Route path="/customers" element={<Customers />} />
        {(user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || '-- no group --').includes('admin') && <Route path="/admin" element={<Admin setProducts={(items)=>{console.log(JSON.stringify(items));setProductList(items)}}/>} />}
      </Route>
    </Routes>
  );

  

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      
      <Header authStatus={authStatus} role={user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || '-- no group --'} />
      <main>
        {shouldShowAuthenticator ? (
          // <AppContext.Provider value={{ setAuthenticatorState }}>
            <Authenticator initialState={initialAuthState as any}>
              {protectedContent}
            </Authenticator>
          // </AppContext.Provider>
        ) : (
          <Routes>
            <Route path="/" element={<PublicHomePage />} />
            <Route path="/public/home" element={<PublicHomePage />} />
          </Routes>
        )}
      </main>
      <Footer />
    </div>
    </ThemeProvider>
  );
};

// const AppContext = React.createContext<{
//   setAuthenticatorState: (state: string) => void;
// }>({
//   setAuthenticatorState: () => {},
// });

const App: React.FC = () => {
  return (
    <CartProvider>
      <BrowserRouter>
      <SnackbarProvider>
        <AppContent />
        </SnackbarProvider>
      </BrowserRouter>
    </CartProvider>
  );
};

export default App;
// export { AppContext };
