import React from "react";
import { Customer, CustomerStatus, CustomerTier, SalesRep } from "../MyTypes";
import CustomerItem from "../Components/CustomerItem";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useEffect } from "react";

import { API } from "aws-amplify";

import { useAuthenticator } from "@aws-amplify/ui-react";

import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";

import { AmplifyUser } from "@aws-amplify/ui";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbar } from "notistack";

interface CustomerListProps {}

const ITEMS_PER_PAGE = 50;


const ProductList: React.FC<CustomerListProps> = () => {

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [salesReps, setsalesReps] = useState<SalesRep[]>([]);
  const [renderedCustomers, setRenderedCustomers] = useState<Customer[]>([]);
  const { user } = useAuthenticator();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [editingCustomer, setEditingCustomer] = useState<Customer | null>(null);
  const { enqueueSnackbar } = useSnackbar();


  // const [editedCustomer, setEditedCustomer] = useState<Customer | null | undefined>(null);
  const [editedFirstName, setEditedFirstName] = useState<string | null | undefined>(null);
  const [editedLastName, setEditedLastName] = useState<string | null | undefined>(null);
  const [editedAddress, setEditedAddress] = useState<string | null | undefined>(null);
  const [editedStatus, setEditedStatus] = useState<
    CustomerStatus | null | undefined
  >(null);
  const [editedTier, setEditedTier] = useState<CustomerTier | null | undefined>(
    null
  );
  const [editedSalesRep, setEditedSalesRep] = useState<number | null | undefined>(
    null
  );
  const [editedBusinessName, setEditedBusinessName] = useState<string | null | undefined>(
    null
  );
  const [editedBusinessEmail, setEditedBusinessEmail] = useState<string | null | undefined>(
    null
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);




 
  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await getMyCustomers(user);

        return response;
      } catch (error) {
        console.error("Error fetching Customer:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    };
    fetchCustomers().then((fetchedCustomers) => {
      setCustomers(fetchedCustomers);
      setHasMoreItems(fetchedCustomers.length >= ITEMS_PER_PAGE);
      console.log("Customers:->", fetchedCustomers.length);
      setRenderedCustomers(fetchedCustomers.slice(0, ITEMS_PER_PAGE));
    });
  }, []);

  useEffect(() => {

    if(!user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'].includes("admin")){
      return;
    }
    const fetchAllSalesReps = async () => {
      console.log("Sales Rep call coming in:->");
      setIsLoading(true);

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await API.get("medineeds", "/customers/allsalesreps", {
          headers: {
            Authorization: `Bearer ${user
              .getSignInUserSession()
              ?.getIdToken()
              .getJwtToken()}`,
          },
        });

        return response;
      } catch (error) {
        console.error("Error fetching Sales Reps:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllSalesReps().then((fetchedSalesReps) => {
      setsalesReps(fetchedSalesReps);
      console.log("fetchedSalesReps:->", fetchedSalesReps.length);
    });
  }, []);

  useEffect(() => {
    const handleSearch = debounce((searchTerm) => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handleSearch(searchTerm);

    // Cleanup
    return () => {
      handleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    setFilteredCustomers(
      customers.filter(
        (customer) =>
          customer?.firstName
            ?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          customer?.lastName
            ?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          customer?.email?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          customer?.address
            ?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase()) ||
          customer?.businessName
            ?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase())
      )
    );
  }, [customers, debouncedSearchTerm]);

  const handleClear = () => {
    setSearchTerm("");
  };

  const fetchCustomersToRender = () => {
    const newRenderedCustomers = [
      ...renderedCustomers,
      ...customers.slice(
        renderedCustomers.length,
        renderedCustomers.length + ITEMS_PER_PAGE
      ),
    ];
    const newHasMoreItems = customers.length > newRenderedCustomers.length;

    console.log(
      "more customers:->",
      [
        ...renderedCustomers,
        ...customers.slice(
          renderedCustomers.length,
          renderedCustomers.length + ITEMS_PER_PAGE
        ),
      ],
      customers.length >= renderedCustomers.length
    );
    setRenderedCustomers(newRenderedCustomers);
    setHasMoreItems(newHasMoreItems);
  };

  const startEditingCustomer = (customer: Customer ) => {
    console.log('startEditingCustomer', customer);
    setEditingCustomer(customer);
    setEditedFirstName(customer.firstName);
    setEditedLastName(customer.lastName);
    setEditedAddress(customer.address);
    setEditedStatus(customer.status);
    setEditedTier(customer.tier);
    setEditedSalesRep(customer.salesRepId);
    setEditedBusinessName(customer.businessName);
    setEditedBusinessEmail(customer.email);
    setIsDialogOpen(true);
    
    
  };
  

  const handleCustomerDelete = (customerId: number) => {
    setCustomers(customers.filter((customer) => customer.id !== customerId));
    setFilteredCustomers(
      customers.filter((customer) => customer.id !== customerId)
    );
    setRenderedCustomers(
      customers.filter((customer) => customer.id !== customerId)
    );
  };

  const handleCustomerUpdate = (updatedCustomer: Customer) => {
    setRenderedCustomers(
      renderedCustomers.map((customer) => {
        if (updatedCustomer.id === customer.id) {
          return updatedCustomer;
        } else return customer;
      })
    );
    setFilteredCustomers(
      renderedCustomers.map((customer) => {
        if (updatedCustomer.id === customer.id) {
          return updatedCustomer;
        } else return customer;
      })
    );
    setCustomers(
      renderedCustomers.map((customer) => {
        if (updatedCustomer.id === customer.id) {
          return updatedCustomer;
        } else return customer;
      })
    );
  };
  const updateCustomer = async (customer: Customer) => {
    setIsLoading(true);
    try {
      await API.put("medineeds", "/customers", {
        headers: {
          Authorization: `Bearer ${user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
        },
        body: [customer],
      });
      return true;
    } catch (error) {
      console.error("Error updating  custoemr:", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateCustomer = async () => {
    setIsLoading(true);
    if (editingCustomer) {
      const updatedCustomer = {
        ...editingCustomer,
        firstName: editedFirstName,
        lastName: editedLastName,
        address: editedAddress,
        businessName: editedBusinessName,
        email: editedBusinessEmail,
        status: editedStatus,
        tier: editedTier,
        salesRepId: editedSalesRep,
        salesRepFirstName:null,
        salesRepLastName:null,

      };
      try {
        const success = await updateCustomer(updatedCustomer);
        if (success) {
          // Update successful, close the dialog
          setIsDialogOpen(false);
          setEditingCustomer(null);
          handleCustomerUpdate({...updatedCustomer,isNewCustomer:false});
          enqueueSnackbar(`Updated customer: ${editingCustomer.id}`, {
            variant: "success",
          });
        }
      } catch (error) {
        console.error("Error updating customer:", error);
        enqueueSnackbar(`Failed to update customer with id ${editingCustomer.id}`, {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  
  const handleAddCustomer = () => {
    let newCustomer: Customer = {
      isNewCustomer: true,
      tier: CustomerTier.Tier1
    }
    setCustomers([...customers, newCustomer]);
    setRenderedCustomers([...renderedCustomers, newCustomer])
    setFilteredCustomers([...filteredCustomers, newCustomer])
    startEditingCustomer(newCustomer);
    
  };

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="200px"
    >
      <LinearProgress />
    </Box>
  ) : (<>
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2} // for margin-bottom
        mt={2} // for margin-top
      >
        <TextField
          id="search"
          label="Search Customers"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            width: "80%",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton size="small" onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <IconButton onClick={handleAddCustomer}>
          <AddCircleIcon color="primary" />
        </IconButton>
        
      </Box>
      <InfiniteScroll
        dataLength={renderedCustomers.length}
        next={fetchCustomersToRender}
        hasMore={hasMoreItems}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>End of customer list</b>
          </p>
        }
      >
        <Grid container spacing={4}>
          {(debouncedSearchTerm ? filteredCustomers : renderedCustomers).map(
            (customer) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={customer.id}>
                <CustomerItem
                  key={customer.id}
                  customer={customer}
                  onUpdate={handleCustomerUpdate}
                  onDelete={handleCustomerDelete}
                  onStartEditingCustomer={startEditingCustomer}
                  salesReps={salesReps}
                />
              </Grid>
            )
          )}
        </Grid>
      </InfiniteScroll>
    </Container>
    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
    <DialogTitle id="edit-customer-dialog">
      {editingCustomer?.id?"Edit Customer:":"Add Customer"} {editingCustomer?.businessName}
    </DialogTitle>
    <DialogContent>
      <Typography variant="h6">Basic Information</Typography>
      <TextField
        autoFocus
        margin="dense"
        id="businessName"
        label="Business Name"
        value={editedBusinessName}
        onChange={(e) => setEditedBusinessName(e.target.value)}
        fullWidth
      />
      {editingCustomer?.isNewCustomer && (<TextField
        autoFocus
        margin="dense"
        id="email"
        label="Business email"
        value={editedBusinessEmail}
        onChange={(e) => setEditedBusinessEmail(e.target.value)}
        fullWidth
      />)}
      <TextField
        autoFocus
        margin="dense"
        id="firstname"
        label="First Name"
        value={editedFirstName}
        onChange={(e) => setEditedFirstName(e.target.value)}
        fullWidth
      />
      <TextField
        autoFocus
        margin="dense"
        id="lastname"
        label="Last Name"
        value={editedLastName}
        onChange={(e) => setEditedLastName(e.target.value)}
        fullWidth
      />
      <TextField
        margin="dense"
        id="address"
        label="Address"
        value={editedAddress}
        onChange={(e) => setEditedAddress(e.target.value)}
        fullWidth
      />
  
      <Typography variant="h6" style={{ marginTop: "20px" }}>
        Additional Details
      </Typography>
      <FormControl fullWidth margin="dense">
        <InputLabel id="tier-label">Customer Pricing Tier</InputLabel>
        <Select
          labelId="tier-label"
          id="tier"
          value={editedTier}
          onChange={(e) =>
            setEditedTier(e.target.value as CustomerTier)
          }
        >
          <MenuItem value={CustomerTier.NoPrice}>No Price</MenuItem>
          <MenuItem value={CustomerTier.Tier1}>Tier 1 </MenuItem>
          <MenuItem value={CustomerTier.Tier2}>
            Tier 2 (Discounted)
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth margin="dense">
        <InputLabel id="salesRep-label">Assigned Sales Rep</InputLabel>
        <Select
          labelId="salesRep-label"
          id="salesRep"
          value={editedSalesRep}
          onChange={(e) =>
            setEditedSalesRep(Number.parseInt(e.target.value as string))
          }
        >
          
          {salesReps?.map((salesRep) => (
            <MenuItem
              key={salesRep.id}
              value={salesRep.id}
            >
              {salesRep.firstName +
                " " +
                salesRep.lastName +
                " (Commission - " +
                salesRep.commission +
                "%)"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  
      <FormControlLabel
        control={
          <Switch
            checked={editedStatus === CustomerStatus.Active}
            onChange={(e) =>
              setEditedStatus(
                e.target.checked
                  ? CustomerStatus.Active
                  : CustomerStatus.Inactive
              )
            }
            color="primary"
          />
        }
        label={
          "Customer can " +
          (editedStatus === CustomerStatus.Active ? "" : "NOT ") +
          "place order"
        }
      />
    </DialogContent>
    <DialogActions>
      <Button
        disabled={isLoading}
        onClick={() => setIsDialogOpen(false)}
        color="primary"
      >
        Cancel
      </Button>
      <Button
        disabled={isLoading}
        onClick={handleUpdateCustomer}
        color="primary"
      >
        Update
      </Button>
    </DialogActions>
  </Dialog></>
  );
};

export default ProductList;

//used in Header for adding my customers to cart context for sales rep to select a customer and build order for that customer
// refactor and move to a common file
// do not change with out testing the sales rep build order flow
export async function getMyCustomers(user: AmplifyUser) {
  return await API.get("medineeds", "/customers", {
    headers: {
      Authorization: `Bearer ${user
        .getSignInUserSession()
        ?.getIdToken()
        .getJwtToken()}`,
    },
  });
}

