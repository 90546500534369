// src/Product.ts
export interface Product {
    id?: number;
    name: string;
    description: string;
    price: number;
    mrp: number;
    imageUrl: string;
    isPriceControlled: number;
    adjustedPrice:number|null;
  }

 export  enum CustomerStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    Pending = 'Pending',
    // add other statuses here
}

export  enum CustomerTier {
  NoPrice = 'NO_PRICE',
  Tier1 = 'TIER_1',
  Tier2 = 'TIER_2'
  // add other statuses here
}
  export interface Customer {
    id?: number ;
    firstName?: string | null;
    lastName?: string| null
    email?: string | null;
    phoneNumber?: string| null;
    address?: string| null;
    businessName?:string| null
    status?: CustomerStatus | null;
    tier?:CustomerTier | null;
    salesRepId?: number | null;
    salesRepFirstName?: string | null;
    salesRepLastName?: string | null;
    salesRepEmail?: string;
    isNewCustomer?:boolean;
  }

  export interface SalesRep {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
    businessName: string;
    status?: CustomerStatus | null;
    commission?: number | null;
  }

  // types.ts
export interface SalesOrder {
  id: number;
  date: Date;
  customerId: number;
  salesRepEmail: string;
  totalPrice: number;
  totalCommission: number;
  salesRepBusinessName: string;
  customerBusinessName: string;
}
export interface SalesOrderDetails {
  salesOrder: SalesOrder,
  salesORderLineItems: SalesOrderLineItem[]
}
export interface SalesOrderLineItem {
  id: number;
  productId: number;
  quantity: number;
  price: number;
  salesRepSuggestedPrice: number;
  imageURL: string;
  productName: string;
}


