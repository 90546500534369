// OrderDetails.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {  SalesOrderDetails, SalesOrderLineItem } from "../MyTypes";

import { AmplifyUser } from "@aws-amplify/ui";
import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Box } from "@mui/system";


  async function getOrderDetails(orderId:string|undefined, user: AmplifyUser) {
    if(!orderId){
        console.log('Missing order id in path');
        return false;
    }
    return await API.get("medineeds", "/orders/"+orderId, {
      headers: {
        Authorization: `Bearer ${user
          .getSignInUserSession()
          ?.getIdToken()
          .getJwtToken()}`,
      },
    });
  }

const OrderDetails: React.FC = () => {
  const [salesOrderDetails, setSalesOrderDetails] = useState<SalesOrderDetails | null>(null);
  const { orderId } = useParams<{ orderId: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuthenticator();

  const navigate = useNavigate();

  useEffect(() => {

    const fetchOrders = async () => {
      setIsLoading(true);

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await getOrderDetails(orderId,user);

        return response;
      } catch (error) {
        console.error("Error fetching Orders:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders().then((fetchedOrderDetails) => {
        setSalesOrderDetails(fetchedOrderDetails)
      console.log("Orders:->", fetchedOrderDetails);
    });
  }, [orderId]);



  if (!salesOrderDetails || !(salesOrderDetails?.salesOrder)) return <div>Loading...</div>;

  return (
    <div>
        <Button onClick={() => navigate(-1)} variant="contained" color="primary" style={{ marginBottom: '10px' }}>Back to Orders</Button> {/* This is the back button */}
        <Button onClick={() => window.print()} variant="contained" color="secondary" style={{ marginBottom: '10px', marginLeft: '10px' }}>Print This Order</Button>
      <h1>Order ID: {salesOrderDetails.salesOrder.id}</h1>
      <p>Order Date: {new Date(salesOrderDetails.salesOrder.date).toLocaleDateString()}</p>
      <p>Customer Name: {salesOrderDetails.salesOrder.customerBusinessName}</p>
      <p>Order Total: {salesOrderDetails.salesOrder.totalPrice}</p>
      <p>Sales Rep Name: {salesOrderDetails.salesOrder.salesRepBusinessName}</p>
      <p>Sales Rep Commission: {salesOrderDetails.salesOrder.totalCommission}</p>
      <List>
          {salesOrderDetails.salesORderLineItems.map((item: SalesOrderLineItem) => (
            <ListItem key={item.id}>
              <Grid container alignItems="center">
                <Grid item xs={10} sm={8} md={4}>
                  <Box display="flex" alignItems="center">
                    <ListItemAvatar>
                      <Avatar alt={item.productName} src={item.imageURL} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.productName}
                      secondary={`Quantity: ${item.quantity} - Price: ${item.price} `}
                    />
                  </Box>
                </Grid>
                
                
              </Grid>
            </ListItem>
          ))}
        </List>
    </div>
  );
};

export default OrderDetails;
