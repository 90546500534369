// src/CartContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Customer, Product } from './MyTypes';


interface CartItem extends Product {
  quantity: number;
  salesRepSuggestedPrice?: number;
}

interface CartContextValue {
  items: CartItem[];
  selectedCustomer?:Customer;
  addItem: (product: Product, quantity:number) => void;
  removeItem: (productId: number) => void;
  updateItem: (productId: number, quantity: number, salesRepSuggestedPrice?:number) => void;
  updateSelectedCustomer: (customer:Customer | undefined) => void;
  clearCart: () => void;
  getTotalItemsInCart: ()=>number;
}

const CartContext = createContext<CartContextValue | undefined>(undefined);


interface CartProviderProps {
    children: React.ReactNode;
  }

const CartProvider: React.FC<CartProviderProps> = ({ children}:any) => {

  const initialItems = localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems')!)
    : [];
    const initialSelectedCustomer = localStorage.getItem('currentSelectedCustomer')
    ? JSON.parse(localStorage.getItem('currentSelectedCustomer')!)
    : [];
  
  const [items, setItems] = useState<CartItem[]>(initialItems);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(initialSelectedCustomer);

  useEffect(() => {
    const cartItems = localStorage.getItem('cartItems');
    if (cartItems) {
      setItems(JSON.parse(cartItems));
    }
  }, []);
  useEffect(() => {
    const selectedCustomer = localStorage.getItem('currentSelectedCustomer');
    if (selectedCustomer) {
      setSelectedCustomer(JSON.parse(selectedCustomer));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(items));
  }, [items]);
  useEffect(() => {
    localStorage.setItem('currentSelectedCustomer', JSON.stringify(selectedCustomer));
  }, [selectedCustomer]);

const addItem = (product: Product, quantity: number): number => {
    let updatedQuantity = 0;
  
    setItems((prevItems) => {
      return prevItems.reduce((newItems, currentItem) => {
        if (currentItem.id === product.id) {
          updatedQuantity = currentItem.quantity + quantity;
          newItems.push({ ...currentItem, quantity: updatedQuantity });
        } else {
          newItems.push(currentItem);
        }
        return newItems;
      }, [] as CartItem[]);
    });
  
    if (updatedQuantity === 0) {
      updatedQuantity = quantity;
      setItems((prevItems) => [...prevItems, { ...product, quantity: updatedQuantity }]);
    }
  
    return updatedQuantity;
  };
  const removeItem = (productId: number) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== productId));
  };

  const updateItem = (productId: number, quantity: number, salesRepSuggestedPrice?: number) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === productId ? { ...item, quantity, salesRepSuggestedPrice } : item))
    );
  };
  const updateSelectedCustomer = (customer:Customer | undefined) => {
    setSelectedCustomer(customer);
    clearCart();
  };

  const clearCart = () => {
    setItems([]);
  };

  const getTotalItemsInCart = () => {
    let totalQuantity = 0;
    
    items.forEach((item)=>{
        totalQuantity = totalQuantity + item.quantity;
    })
    return totalQuantity;
    };



  return (
    <CartContext.Provider value={{ items, selectedCustomer, addItem, removeItem, updateItem, clearCart , getTotalItemsInCart, updateSelectedCustomer}}>
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };


export function useCart(): CartContextValue {
    const context = useContext(CartContext);
    if (!context) {
      throw new Error('useCart must be used within a CartProvider');
    }
    return context;
  }
