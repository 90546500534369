import { useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { useTable, useFilters, useGlobalFilter,usePagination, useRowSelect } from 'react-table';
import { DefaultColumnFilter, GlobalFilter } from '../Components/filters';
import OrderRow from '../Components/OrderRow';

import { AmplifyUser } from "@aws-amplify/ui";
import { SalesOrder } from '../MyTypes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';


 async function getOrders(user: AmplifyUser) {
  return await API.get("medineeds", "/orders", {
    headers: {
      Authorization: `Bearer ${user
        .getSignInUserSession()
        ?.getIdToken()
        .getJwtToken()}`,
    },
  });
}

export const Orders: React.FC = () => {
  const [orders, setOrders] = useState<SalesOrder[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useAuthenticator();
  const printContentRef = useRef(null);

const location = useLocation();

  useEffect(() => {

    const fetchOrders = async () => {
      setIsLoading(true);

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await getOrders(user);

        return response;
      } catch (error) {
        console.error("Error fetching Orders:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrders().then((fetchedOrders) => {
      setOrders(fetchedOrders)
      console.log("Orders:->", fetchedOrders.length);
    });
  }, []);

  useEffect(() => {
    // This logic will open the modal when there is content in the Outlet
    if (location.pathname.startsWith("/orders/") && location.pathname !== "/orders") {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [location]);

  const calculateTotals = (rows:any) => {
    let totalCommission = 0;
    let totalPrice = 0;
  
    rows.forEach((row : any) => {
      totalPrice += Number.parseFloat(row.values.totalPrice || 0);
      totalCommission += Number.parseFloat(row.values.totalCommission || 0);
    });
  
    return { totalCommission, totalPrice };
  };


  const columns = React.useMemo(
    () => [
      {
        Header: 'Order Number',
        accessor: 'id' as keyof SalesOrder,
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Order Date',
        accessor: 'date' as keyof SalesOrder,
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerBusinessName' as keyof SalesOrder,
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Total Price',
        accessor: 'totalPrice' as keyof SalesOrder,
        Filter: DefaultColumnFilter,
        disableFilters: true, 
        
      },
      {
        Header: 'Sales Rep',
        accessor: 'salesRepBusinessName' as keyof SalesOrder,
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Sales Rep Commission',
        accessor: 'totalCommission' as keyof SalesOrder,
        Filter: DefaultColumnFilter,
        disableFilters: true, 
        HeaderStyle: { 
          borderBottom: 'solid 3px red', 
          background: 'aliceblue',
          color: 'black',
          fontWeight: 'bold',
          textAlign: 'left',
          padding: '5px'
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
    state: { pageIndex, pageSize },
    page,
  } = useTable(
    {
      columns,
      data: orders,
    },
    useFilters, useGlobalFilter, usePagination, useRowSelect
  ) as any;

  const { totalCommission, totalPrice } = calculateTotals(rows);


  return (<>
    
    <div>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}  style={{
                  borderBottom: 'solid 3px red',
                  background: 'lightgray',
                  color: 'black',
                  fontWeight: 'bold',
                }}>
                  {column.render('Header')}
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i:any) => {
            prepareRow(row);
            return <OrderRow rowIndex={i} {...row.getRowProps()} order={row.original} />;
          })}
        </tbody>
        <tfoot>
      <tr >
      <td></td>
      <td></td>
      <td></td>
        <td style={{
                borderTop: 'solid 3px red',
                background: 'aliceblue',
                color: 'black',
                fontWeight: 'bold',
              }}>Total Price: {totalPrice.toFixed(2)}</td>
              <td></td>
        <td style={{
                borderTop: 'solid 3px red',
                background: 'aliceblue',
                color: 'black',
                fontWeight: 'bold',
              }}>Total Commission: {totalCommission.toFixed(2)}</td>
      </tr>
    </tfoot>
      </table>
    </div></>
  );
};

export default Orders;
