// src/Header.tsx
import React, { SyntheticEvent, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { Link, useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";

import { useCart } from "./CartContext";
import { styled } from "@mui/system";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Customer, CustomerStatus } from "./MyTypes";

import Alert from '@mui/material/Alert';
import { SnackbarCloseReason } from "@mui/base";
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select } from "@mui/material";
import { getMyCustomers } from "./routes/Customers";
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

interface HeaderProps {
  authStatus: string;
  role: string;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "red",
  },
}));

const Header: React.FC<HeaderProps> = ({ authStatus, role }) => {
  const navigate = useNavigate();
  const {user} = useAuthenticator();
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleSignIn = async () => {
    navigate("/pricelist"); //is this the right place?
  };

  const isLoggedIn = authStatus === "authenticated";
  const { getTotalItemsInCart, selectedCustomer, updateSelectedCustomer } = useCart();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [customerStatus, setCustomerStatus] = React.useState<string|undefined|null>(null);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editedSelectedCustomer, setEditedSelectedCustomer] = useState<number | null | undefined>(
    null
  );
  const [myCustomers, setMyCustomers] = useState<Customer[]>([]);
  
  const handleBannerClose = (event: SyntheticEvent<Element, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsBannerVisible(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSelectCustomer = () => {
   
    handleMenuClose();
    setEditedSelectedCustomer(selectedCustomer?.id);
    setIsDialogOpen(true);

  };
  const handleUpdateSelectedCustomer = () => {
   
    let newSelectedCustomer  = myCustomers.find((customer)=>{
      return editedSelectedCustomer===customer.id;
    });
    updateSelectedCustomer(newSelectedCustomer);
    setIsDialogOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      console.log("Customer details call coming in:->");

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await API.get("medineeds", "/customers/details", {
          headers: {
            Authorization: `Bearer ${user
              .getSignInUserSession()
              ?.getIdToken()
              .getJwtToken()}`,
          },
        });

        return response;
      } catch (error) {
        console.error("Error fetching Customer details:");
        return [];
      } 
    };
    fetchCustomerDetails().then((fetchedCustomer:Customer[]) => {
      setCustomerStatus(fetchedCustomer && fetchedCustomer[0]?.status);
    });
  }, [user]);

  useEffect(() => {
    const fetchCustomers = async () => {
      console.log("Customer call coming in from header :->");
      setIsLoading(true);

      try {
        // console.log(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}` );
        const response = await getMyCustomers(user);

        return response;
      } catch (error) {
        console.error("Error fetching Customer:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    };
    fetchCustomers().then((fetchedCustomers) => {
      setMyCustomers(fetchedCustomers);
    });
  }, [user]);


  return (
    <>
    <AppBar
      sx={{ marginBottom: "5px", backgroundColor: "white" }}
      color="primary"
      position="static"
    >
      <Toolbar>
        <IconButton color="primary" component={Link} to="/pricelist">
          <HomeIcon color="primary" />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link
            to="/pricelist"
            style={{ textDecoration: "none", color: "primary" }}
          >
            {/* Add your logo here */}
            {/* Replace "PharmaDistributor" with your desired logo */}

            <img
              className="headerImage"
              src="https://medineeds-dev-imgs.s3.amazonaws.com/assets/medineeds-logo-header.png"
              alt="Medineeds"
            />
            {customerStatus === CustomerStatus.Pending && isBannerVisible && (
            <Alert severity="info" onClose={handleBannerClose}>
              Note: You will not be able to check out while waiting for approval. Please have a look around in the meanwhile.
            </Alert>
          )}
          </Link>
        </Typography>
        {isLoggedIn && (
          <IconButton
            disabled={getTotalItemsInCart() > 0 ? false : true}
            color="primary"
            component={Link}
            to="/cart"
          >
            <StyledBadge
              overlap="rectangular"
              badgeContent={getTotalItemsInCart()}
              color="primary"
            >
              <ShoppingCartIcon color={getTotalItemsInCart() > 0 ? "primary" : "disabled"} />
            </StyledBadge>
          </IconButton>
        )}

        {(role.includes("admin") || role.includes("sales_rep")) && (
          <>
            <Typography className="hideOnPrint" variant="body2" color="text.secondary">
            {selectedCustomer?.id? "Working on Order for : "+ selectedCustomer?.businessName : "No Customer Selected"}
          </Typography>
            <IconButton onClick={handleMenuOpen}>
                <AccountCircleIcon color="primary" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {(!role.includes("sales_rep") && <MenuItem onClick={handleMenuClose} component={Link} to="/admin">
                Upload File From Tally
              </MenuItem>)}
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/customers"
              >
                Manage Customers
              </MenuItem>
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/orders"
              >
                View Orders
              </MenuItem>
              <MenuItem
                onClick={handleSelectCustomer}
              >
               Select Customer
              </MenuItem>
            </Menu>
          </>
        )}

        <IconButton
          color="primary"
          onClick={isLoggedIn ? handleSignOut : handleSignIn}
        >
          {isLoggedIn ? <LogoutIcon /> : <LoginIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle id="edit-customer-dialog">
          Selected Customer: {selectedCustomer?.id} (
          {selectedCustomer?.email})
        </DialogTitle>
        <DialogContent>
          
          <FormControl fullWidth margin="dense">
            <InputLabel id="tier-label">Select Customer </InputLabel>
            <Select
              labelId="tier-label"
              id="tier"
              value={editedSelectedCustomer}
              onChange={(e) => setEditedSelectedCustomer(Number.parseInt(e.target.value as string))}
            >{myCustomers?.map(
              (customer)=>(
                <MenuItem key={customer.id} value={customer.id}>{customer.businessName + " ("+customer.email}</MenuItem>
              )
            )}
              
              
              {/* Add more options here as per your enum */}
            </Select>
          </FormControl>

          
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={() => setIsDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleUpdateSelectedCustomer}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;
