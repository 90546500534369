import { Container, Typography } from "@mui/material";
import React from "react";
import theme from "../theme";


const OrderConfirmation: React.FC = () => {

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
    }}>
      <Typography variant="h4" color="textPrimary" sx={{
     marginBottom: theme.spacing(2),
  }}>
        Order Received
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Your order has been received and is pending processing. You will soon receive a confirmation email.
      </Typography>
      {/* <Box mt={4}>
        <Link to="/orders" className={classes.link}>
          <Button variant="contained" color="primary">
            View Orders
          </Button>
        </Link>
      </Box> */}
    </Container>
  );
};

export default OrderConfirmation;
