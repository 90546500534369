import React, { useEffect } from "react";
import { useCart } from "../CartContext";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import OrderConfirmation from "./OrderConfirmation";

import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { CustomerTier } from "../MyTypes";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import DeleteIcon from "@mui/icons-material/Delete";

const ShoppingCart: React.FC = () => {
  const { items, selectedCustomer, updateItem, removeItem, clearCart } =
    useCart();

  const cartIsEmpty = items.length === 0;

  const [checkedOut, setCheckedOut] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isCheckoutDialogOpen, setIsCheckoutDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  async function getUserRoles() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.idToken.payload["cognito:groups"];
      return groups || [];
    } catch (error) {
      console.error("Error checking user group:", error);
      return [];
    }
  }

  const [userRoles, setUserRoles] = useState<string[]>([]);

  useEffect(() => {
    getUserRoles().then(setUserRoles);
  }, []);

  const handleCheckout = async () => {
    setIsLoading(true);
    // Perform any necessary actions like sending the order to the server
    console.log("Sending these items to the server ", items);
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.idToken.payload["cognito:groups"];
    const isWorkingOnBehalfOfBuyer =
      groups && (groups.includes("admin") || groups.includes("sales_rep"));
    try {
      await API.put("medineeds", "/checkout", {
        headers: {
          Authorization: `Bearer ${user
            .getSignInUserSession()
            ?.getIdToken()
            .getJwtToken()}`,
          Selectedcustomeremail: isWorkingOnBehalfOfBuyer
            ? selectedCustomer?.email
            : undefined,
        },
        body: items,
      });
      setCheckedOut(true);
      clearCart();
    } catch (error: any) {
      console.error("Error checking out  cart:", error);
      if (error.response && error.response.status === 500) {
        // Access the response body when there is a 500 error
        const responseBody = error.response;
        console.log("Response body:", responseBody);
        if (
          (responseBody.data as string).indexOf("No active customer found") !==
          -1
        ) {
          enqueueSnackbar(
            `We are sorry. You can not check out currently. Please contact Medineeds to update your status `,
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar(`Check out failed. Please contact Medineeds `, {
            variant: "error",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuantityChange = (
    productId: number,
    newQuantity: number,
    salesRepSuggestedPrice: number
  ) => {
    updateItem(productId, newQuantity); // Assume updateItem is a function that updates the quantity of a given item
  };
  const handlesalesRepSuggestedPriceChange = (
    productId: number,
    newQuantity: number,
    salesRepSuggestedPrice: number
  ) => {
    updateItem(productId, newQuantity, salesRepSuggestedPrice); // Assume updateItem is a function that updates the quantity of a given item
  };

  const handleDelete = (productId: number) => {
    removeItem(productId); // Assume removeItem is a function that removes an item from the cart
  };

  return checkedOut ? (
    <OrderConfirmation />
  ) : (<>
    <Box mt={4}>
      <Typography variant="h4" component="h2">
        Shopping Cart
      </Typography>
      {cartIsEmpty ? (
        <Typography variant="body1">Your cart is empty.</Typography>
      ) : (
        <List>
          {items.map((item: any) => (
            <ListItem key={item.id}>
              <Grid container alignItems="center">
                <Grid item xs={10} sm={8} md={4}>
                  <Box display="flex" alignItems="center">
                    <ListItemAvatar>
                      <Avatar alt={item.name} src={item.imageUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.name}
                      secondary={`Quantity: ${item.quantity} - Price: ${
                        selectedCustomer?.tier !== CustomerTier.NoPrice
                          ? "₹" + (item.price * item.quantity).toFixed(2)
                          : "regular price"
                      } `}
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} sm={1} md={1}>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                {(userRoles.includes("admin") ||
                  userRoles.includes("sales_rep")) && (
                  <Grid item xs={12} sm={4} md={2}>
                    <Typography>Suggested Price: </Typography>
                    <TextField
                      type="number"
                      value={item.salesRepSuggestedPrice}
                      onChange={(event) =>
                        handlesalesRepSuggestedPriceChange(
                          item.id,
                          item.quantity,
                          parseInt(event.target.value)
                        )
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={5}>
                  <Typography>Qty In Cart: </Typography>
                  <TextField
                    type="number"
                    inputProps={{ min: 1, style: { textAlign: "center" } }}
                    value={item.quantity}
                    onChange={(event) =>
                      handleQuantityChange(
                        item.id,
                        parseInt(event.target.value),
                        item.salesRepSuggestedPrice
                      )
                    }
                  />
                  <IconButton
                    onClick={() =>
                      handleQuantityChange(
                        item.id,
                        item.quantity + 1,
                        item.salesRepSuggestedPrice
                      )
                    }
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleQuantityChange(
                        item.id,
                        item.quantity - 1,
                        item.salesRepSuggestedPrice
                      )
                    }
                    size="small"
                    disabled={item.quantity <= 1}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      )}
      {!cartIsEmpty && (
        <Typography variant="h6">
          Total:
          {(selectedCustomer?.tier !== CustomerTier.NoPrice &&
            "₹" +
              items
                .reduce(
                  (total: any, item: any) => total + item.price * item.quantity,
                  0
                )
                .toFixed(2)) ||
            "Will be calculated when invoiced"}
        </Typography>
      )}
      <Box mt={2}>
        <Button
          disabled={isLoading || items.length === 0}
          variant="contained"
          color="primary"
          onClick={()=>setIsCheckoutDialogOpen(true)}
        >
          {isLoading ? "Loading..." : "Check Out"}
        </Button>
      </Box>
      <Box mt={2}>
        <Link to="/pricelist">Continue building Order</Link>
      </Box>
    </Box>
    <Dialog
  open={isCheckoutDialogOpen}
  onClose={() => setIsCheckoutDialogOpen(false)}
>
  <DialogTitle>{(userRoles.includes("admin") ||
                  userRoles.includes("sales_rep")) ?"Customer: "+selectedCustomer?.businessName:"Checkout shopping cart?"} </DialogTitle>
  <DialogContent>
    <DialogContentText>
    {(userRoles.includes("admin") ||
                  userRoles.includes("sales_rep")) ?"An order will be created for this customer. Press Okay to continue, or cancel to select a different customer.":
                  "Please click Okay to create this order."}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsCheckoutDialogOpen(false)}>Cancel</Button>
    <Button
      onClick={() => {handleCheckout();
        setIsCheckoutDialogOpen(false);
      }}
    >
      Okay
    </Button>
  </DialogActions>
</Dialog>
    
    </>
  );
};

export default ShoppingCart;
