// src/PublicHomePage.tsx
import React from "react";
import "./PublicHomePage.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
// import { useContext } from 'react';
// import {AppContext} from '../App'

const PublicHomePage: React.FC = () => {
  const navigate = useNavigate();

  // const { setAuthenticatorState } = useContext(AppContext);

  const handleSignUpClick = () => {
    // setAuthenticatorState('signUp');
    navigate("/pricelist", { state: { initialAuthState: "signUp" } });
  };

  return (
    <div className="PublicHomePage">
      <section className="hero">
        <h1>Trusted Pharmaceutical Distributor</h1>
        <p>Servicing the needs of Medical shops and Hospitals since 1994</p>
        <div className="imageContainer">
          <img
            className="centerImage"
            src="https://medineeds-dev-imgs.s3.amazonaws.com/assets/medineeds-logo.png"
            alt="Pharmaceutical products"
          />
          <br /> 
        </div>
        <div >
          <Button
            className="callToAction"
            variant="contained"
            color="primary"
            onClick={handleSignUpClick}
          >
            Create Account
          </Button></div>
      </section>

      <section className="features">
  <div className="feature">
    <i className="fas fa-network-wired feature-icon"></i>
    <h3>Unmatched Sourcing Capabilities</h3>
    <p>We excel in sourcing any product from a multitude of pharmaceutical producers and distributors. Our extensive network ensures we can meet all your diverse needs, including niche products.</p>
    <button  onClick={handleSignUpClick} className="feature-button">Start Today</button>
  </div>
  <div className="feature">
    <i className="fas fa-tag feature-icon"></i>
    <h3>Affordable Excellence</h3>
    <p>Value for money is paramount to us. With our streamlined supply chain and strong partnerships, we offer top-quality pharma products at competitive prices.</p>
    <button className="feature-button" onClick={handleSignUpClick}>Start Today</button>
  </div>
  <div className="feature">
    <i className="fas fa-shipping-fast feature-icon"></i>
    <h3>Swift and Reliable Delivery</h3>
    <p>Time is critical in our industry. That's why our efficient logistics network ensures prompt delivery of your orders, keeping your stock levels optimal.</p>
    <button className="feature-button" onClick={handleSignUpClick}>Start Today</button>
  </div>
  <div className="feature">
    <i className="fas fa-handshake feature-icon"></i>
    <h3>Tailored to You</h3>
    <p>We offer a personalized service that sets us apart. We're more than a supplier - we're a partner, always ready to assist you every step of the way.</p>
    <button className="feature-button" onClick={handleSignUpClick}>Start Today</button>
  </div>
</section>


      <section className="contact">
        <h2>Contact Us</h2>
        <p>For inquiries and orders, please contact us at:</p>
        <p>
          Phone: +91 949 577 5663
          <br />
          Email: info@medineedsindia.in
        </p>
        <p>
          Medineeds, Opp. Nice Movie House
          <br />
          Thalayolaparambu PO, Kottayam, Kerala - 686605
        </p>
      </section>
    </div>
  );
};

export default PublicHomePage;
