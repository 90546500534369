import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import CssBaseline from "@mui/material/CssBaseline";
import { CartProvider } from "./CartContext"; // Import CartProvider

import App from "./App";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);




// const router = createBrowserRouter([
//   {
//     path: "/",
//     errorElement: <ErrorPage />,
//     element: <Root />,
//     children: [
//       {
//         path: "login",
//         element: <Login />,
//         children: [
//           {
//             path: "login/pricelist",
//             element: <Pricelist />,
//           },
//           {
//             path: "login/cart",
//             element: <ShoppingCart />,
//           },
//         ],
//       },
//     ],
//   },
// ]);



ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <CssBaseline />
    <Authenticator.Provider>
    <CartProvider>
      <App/>
    </CartProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

reportWebVitals();
