import React, { useRef } from 'react';
import { Product } from '../MyTypes';

import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

import { useNavigate } from 'react-router-dom';

import * as XLSX from 'xlsx';







interface AdminProps {
  setProducts: (products: Product[]) => void;
}



export const Admin: React.FC<AdminProps> = ({ setProducts }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const saveProducts = async (products: Product[]) => {
    const user = await Auth.currentAuthenticatedUser();
  try {
    await API.put("medineeds", "/products", {
      headers: {
        Authorization: `Bearer ${user
          .getSignInUserSession()
          ?.getIdToken()
          .getJwtToken()}`,
      },
      body: products,
    });
    navigate('/pricelist'); //is this the right place?
    return true;
    
  } catch (error) {
    console.error("Error updating  product:", error);
    return false;
  }
};

// const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
//   const file = e.target.files?.[0];
//   if (file) {
//     parse(file, {
//       header: true,
//       skipEmptyLines: true,
//       complete: async (result: any) => {
//         const rows = result.data;
//         const products: Product[] = [];
//         let rowNum=1;
//         for (let i = 14; i < rows.length; i++) {
//           const row = rows[i];
//           const product: Product = {
//             name: row["MEDINEEDS"],
//             price: row["_1"]?row["_1"]:0,
//             mrp: row["_1"]?row["_1"]:0,
//             imageUrl: '',
//             description: ''
//           };
//           console.log('rowNum',rowNum++,"->",row);
//           if(row["MEDINEEDS"]==='Grand Total') break;
//           products.push(product);
//         }

//         console.log('products', products);
//          await saveProducts(products);
//       },
//     });
//   }
// };
const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const file = e.target.files?.[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any[];

      const products: Product[] = [];

      let rowNum=1;
      for (let i = 15; i < rows.length; i++) {
        const row = rows[i];
        const product: Product = {
          name: row[0], // Assuming Particulars is in the first column
          price: parseFloat(row[2]), // Assuming Value is in the second column
          mrp: parseFloat(row[2]), // Assuming Value is in the second column
          imageUrl: '',
          description: '',
          isPriceControlled: 0, // can be another column if it can be exported form tally
          adjustedPrice: null // can do pricing from excel too if needed

        };

        console.log('rowNum',rowNum++,"->",row);
          if(row[0]==='Grand Total') break;
          products.push(product);
      }

      console.log('products', products);
      await saveProducts(products);
    };

    reader.readAsArrayBuffer(file);
  }
};

  return (
    <div>
      <h1>Admin - Upload file from Tally</h1>
      <input
        type="file"
        ref={inputRef}
        onChange={handleFileUpload}
        accept=".csv,.xlsx"
      />
    </div>
  );
};
